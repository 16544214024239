@font-face {
  font-family: 'Intro_Rust';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Intro_Rust.otf') format('opentype');
}

@font-face {
  font-family: 'Intro_Book_Caps';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Intro_Book_Caps.otf');
}
