@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Noto+Sans+JP:wght@700&family=Nunito:wght@400;500;600;700&family=Roboto:wght@300;400;500;700;900&display=swap');
body {
  margin: 0;
  padding: 0;
  // font-family: 'Lato', sans-serif;
  //font-size: 18px;
  line-height: 1.44;
  color: #4a4a4a;
  overflow-x: hidden;
  font-family: Lato;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4a4a4a !important;
  // background-blend-mode: 'multiply, normal';
  // background-image: linear-gradient(
  //     to top,
  //     rgba(255, 255, 255, 0.5),
  //     rgba(0, 0, 0, 0.5)
  //   ),
  //   linear-gradient(to bottom, #da0b85, #da0b85);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bg-transparent {
  background-color: transparent !important;
}

a {
  color: #30b9cf;
}
