.MuiAppBar-positionFixed {
  position: relative !important;
  background-color: #1f2029 !important;
}
@media (max-width: 1092px) {
  .MuiAppBar-positionFixed {
    position: unset !important;
  }
}
.MuiToolbar-regular {
  position: relative;
  z-index: 33333 !important;
}
.sliderBtnRight {
  position: absolute;
  top: 50%;
  right: -6%;
  cursor: pointer;
  color: white;
}
.sliderBtnLeft {
  position: absolute;
  top: 50%;
  left: -6%;
  cursor: pointer;
  z-index: 99;
  color: white;
  font-size: 20px;
}

.sliderBtnLeft > svg,
.sliderBtnRight > svg {
  font-size: 36px;
}

.slick-slide {
  margin-top: 60px;
  padding-right: 25px;
  box-sizing: border-box;
}
@media (max-width: 1060px) {
  .slick-slide {
    padding-right: 0px;
  }
}

@media (min-width: 1092px) {
  .MuiToolbar-regular {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.orbit-spinning {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.orbit-spinning-0 > img {
  width: 32px;
}

.orbit-spinning-2 > img {
  width: 24px;
}

.orbit-spinning-1 > img,
.orbit-spinning-3 > img,
.orbit-spinning-4 > img {
  width: 14px;
}

.orbit-spinning-0 {
  animation: spin-right 240s linear infinite;
}

.orbit-spinning-1 {
  animation: spin-right 160s linear infinite;
}

.orbit-spinning-2 {
  animation: spin-right 180s linear infinite;
}

.orbit-spinning-3 {
  animation: spin-right 150s linear infinite;
}

.orbit-spinning-4 {
  animation: spin-right 120s linear infinite;
}

.orbit-img {
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);
}

.orbit-container {
  position: absolute;
  transform: translate(-50%, -50%);
}

.orbit-container-0 {
  top: 49.5%;
  left: 49.9%;
  width: 34.2%;
  height: 36%;
}

@media (min-width: 992px) {
  .orbit-container-0 {
    top: 49%;
    left: 50%;
    width: 40.2%;
    height: 40.4%;
  }
}

.orbit-container-1 {
  top: 49.3%;
  left: 49.9%;
  width: 43.2%;
  height: 43.4%;
}

@media (min-width: 992px) {
  .orbit-container-1 {
    top: 49.7%;
    left: 50%;
    width: 51.2%;
    height: 51.4%;
  }
}

.orbit-container-2,
.orbit-container-3 {
  top: 49.3%;
  left: 49.9%;
  width: 56.2%;
  height: 57.4%;
}

@media (min-width: 992px) {
  .orbit-container-2,
  .orbit-container-3 {
    top: 49%;
    left: 50%;
    width: 65.2%;
    height: 64.4%;
  }
}

.orbit-container-4 {
  top: 49.3%;
  left: 49.9%;
  width: 78.2%;
  height: 79.4%;
  display: none;
}

@media (min-width: 992px) {
  .orbit-container-4 {
    top: 49%;
    left: 50%;
    width: 87.2%;
    height: 88.4%;
    display: none;
  }
}

.orbit-rotated-area {
  width: 100%;
  height: 100%;
}

.orbit-rotated-area-0 {
  transform: rotate(300deg);
}

.orbit-rotated-area-1 {
  transform: rotate(270deg);
}

.orbit-rotated-area-2 {
  transform: rotate(0deg);
}

.orbit-rotated-area-3 {
  transform: rotate(70deg);
}

.orbit-rotated-area-4 {
  transform: rotate(135deg);
}

@keyframes spin-right {
  100% {
    transform: rotate(360deg);
  }
}
