.inputContainer {
  margin-bottom: 18px;
}
.inputField {
  position: relative;
  width: 100%;
}

.inputField input {
  padding: 8px 20px;
  padding-top: 18px;
  border: 1.8px solid #e0e0e0;
  border-radius: 10px;
  width: 100%;
  height: 60px;
  color: #000;
  font-family: Lato;
  box-sizing: border-box;
  font-size: 14px;
}

.inputField input:focus {
  border: 1.8px solid #6b6b6b;
  outline: none;
}

.inputField span {
  pointer-events: none;
  font-family: Lato;
  color: #748896;
  position: absolute;
  padding-left: 20px;
  margin-bottom: 12px;
  left: 0;
  /* top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); */
  cursor: text;
}

.inputField input:focus + span,
.inputField input:not(:placeholder-shown) + span {
  top: 2px;
  /* font-size: 12px; */
  /* -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
}

.inputField input,
.inputField span {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
.inputField input[type='number'] {
  -moz-appearance: textfield;
}

.inputField input::-webkit-outer-spin-button,
.inputField input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.inputError {
  border: 1.8px solid #f44336 !important;
}
.error {
  color: #f44336;
  margin: 0px;
  font-family: Lato;
  font-size: 14px;
}
