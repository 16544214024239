.button-default {
  border-radius: 10px;
  border: none;
  min-width: 140px;
  height: 57px;
  color: white;
  font-family: Nunito;
  font-size: 15px;
  cursor: pointer;
}

.button-gredient {
  border-radius: 10px;
  width: 170.07px;
  height: 51.41px;
  color: #fff;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 1px transparent;
  background-image: linear-gradient(4.62deg, #20212a 83.54%, rgba(32, 33, 42, 0.62) 99.54%),
    linear-gradient(180deg, #1bebb5 0.9%, #3291e9 72.79%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #1e3545 inset;
  font-size: 14px;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
}
.button-background {
  background: linear-gradient(248deg, #24e5ad 0.9%, #4567de 112.34%);
  border-radius: 10px;
  border: none;
  min-width: 140px;
  height: 57px;
  color: white;
  font-family: Nunito;
  font-size: 15px;
  cursor: pointer;
}
.button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
.button-small {
  min-width: 100px;
  height: 41px;
}
