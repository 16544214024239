.banner {
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  height: 40px;
  background-color: #476ddc;
  font-family: Nunito;
  font-size: 16px;

  &-link {
    color: #21d7bb;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  &-wrap {
    color: #21d7bb;
    font-weight: bold;
    text-decoration: none;
  }

  &-text {
    color: #dcf3fe;
  }

  &-close--icon {
    position: absolute;
    right: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
